import { useState, useCallback } from "react";
import { useQuery } from "react-query";
import { Row, Col, Table, ButtonGroup, Button } from "reactstrap";
import { fetchItems } from "ApiCall";
import PageContainer from "modules/Container";
import Pagination from "utils/Pagination";
import { useHistory } from "react-router-dom";
import InputComponent from "utils/Input";
import { debounce } from "lodash";
import RSelect from "utils/RSelect";
import { ITEM_STATUS } from "constant";
import { FaTrashAlt, FaPen, FaPlus } from "react-icons/fa";
import Confirm from "utils/Confirm";
import Api from "Api";

const ItemsLanding = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [searchKey, setSearchKey] = useState("");
  const [selectedStatus, setSelectedStatus] = useState([]);

  const debounceSet = useCallback(debounce(setSearchKey, 800), []);

  const filter = {
    page,
    searchKey,
    status: selectedStatus.map((x) => x.value).join(","),
  };

  const {
    data = {},
    isLoading = true,
    refetch,
  } = useQuery(["fetchItems", filter], () => fetchItems(filter));

  const onChange = (e) => {
    debounceSet(e.target.value);
  };

  const statusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const deleteItem = async (id) => {
    await Api.Items.delete(id);
    refetch();
  };

  const statusOptions = Object.keys(ITEM_STATUS).map((key) => {
    return {
      label: key,
      value: key,
    };
  });

  return (
    <PageContainer title={`Item List - ${data?.totalRows || 0} Record's`}>
      <Row className="mb-2">
        <Col>
          <InputComponent
            placeholder="Search Part, Part #, Brand"
            onChange={onChange}
          />
        </Col>
        <Col>
          {" "}
          <RSelect
            placeholder={"Filter Status"}
            options={statusOptions}
            isMulti
            onChange={statusChange}
          />
        </Col>
        <Col className="d-flex justify-content-end">
          <ButtonGroup size="sm">
            <Button
              style={{ height: "40px" }}
              color="success"
              onClick={() => history.push("/items/details/new")}
            >
              <FaPlus /> Create
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
      <div className="border-bottom mb-2" />
      <Table striped hover>
        <thead>
          <tr>
            <th>Part</th>
            <th>Part#</th>
            <th>Brand</th>
            <th>Model Type</th>
            <th>Status</th>
            <th>Item Location</th>
            <th>Signout To</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {!isLoading && data?.result?.length > 0 ? (
            data?.result.map((row) => {
              return (
                <tr key={row.id}>
                  <th scope="row">{row.part}</th>
                  <td>{row.part_num}</td>
                  <td>{row.brand}</td>
                  <td>{row.model_type}</td>
                  <td>{row.status}</td>
                  <td>{row.item_location}</td>
                  <td>{row.signout_to}</td>
                  <td>
                    <ButtonGroup size="sm">
                      <Button
                        tag={"a"}
                        color="primary"
                        onClick={() => {
                          history.push(`/items/details/${row.id}`);
                        }}
                      >
                        <FaPen /> Edit
                      </Button>
                      <Confirm
                        title="Confirm Delete"
                        body="Are you sure want to delete this item?"
                        onConfirm={() => deleteItem(row.id)}
                      >
                        <Button color="danger">
                          <FaTrashAlt /> Delete
                        </Button>
                      </Confirm>
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={8}>
                {isLoading ? "Please wait..." : "No records found..."}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="d-flex justify-content-end">
        {!isLoading && (
          <Pagination
            activePage={page}
            totalPage={data?.totalPage || 1}
            onSelect={(p) => {
              setPage(p);
            }}
          />
        )}
      </div>
    </PageContainer>
  );
};

export default ItemsLanding;
