// import { useDispatch } from "react-redux";
import Api from "Api";
import { useHistory } from "react-router-dom";
import { ListGroup, ListGroupItem } from "reactstrap";
// import { history } from "redux/store";
import styled from "styled-components";

const StyledListGroup = styled(ListGroup)`
  li {
    cursor: pointer;
  }
`;

const LeftNav = () => {
  // const dispatch = useDispatch();
  const history = useHistory();

  const gotoNav = (nav) => {
    history.push({ pathname: nav });
  };
  const logout = async () => {
    await Api.Session.logout();
    history.replace({ pathname: "/login" });
  };
  return (
    <nav
      id="sidebarMenu"
      className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
    >
      <div className="position-sticky pt-3">
        <StyledListGroup className="nav flex-column">
          {/* <ListGroupItem onClick={() => gotoNav("/dashboard")}>
            Dashboard
          </ListGroupItem> */}
          <ListGroupItem onClick={() => gotoNav("/items")}>Items</ListGroupItem>
          <ListGroupItem href="/settings">Settings</ListGroupItem>
          <ListGroupItem onClick={logout}>Logout</ListGroupItem>
        </StyledListGroup>
      </div>
    </nav>
  );
};

export default LeftNav;
