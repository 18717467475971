const hostname = window.location.host;
const protocol = window.location.protocol;

let apiURL;
let fileUrl;
const apiPort = 8081;
const folderName = "go-inventory";

if (process.env.NODE_ENV === "development") {
  const serverUrl = `http://localhost:${apiPort}`;
  apiURL = `${serverUrl}/${folderName}/webapi`;
  fileUrl = `${serverUrl}/${folderName}/files`;
} else {
  apiURL = `${protocol}//${hostname}/webapi`;
  fileUrl = `${protocol}//${hostname}/files`;

  // const serverUrl = `http://localhost:${apiPort}`;
  // apiURL = `${serverUrl}/${folderName}/webapi`;
}

export default {
  baseUrl: window.location.host,
  apiURL: apiURL,
  fileUrl: fileUrl,
};
