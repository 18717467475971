import superagentPromise from "superagent-promise";
import localforage from "localforage";
import _superagent from "superagent";
import config from "./webconfig";

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = config.apiURL;

const responseBody = (res) => res.body;

let token = null;

const tokenPlugin = (req) => {
  if (token) {
    req.set("Authorization", `Bearer ${token}`);
  }
};
const unauthorizedRedirect = (req) => {
  req.on("response", (res) => {
    if (res.status === 401) {
      // store.dispatch({type: LOGOUT });
      window.location.href = "/login";
    }
  });
};

const Request = {
  del: (url) => {
    return superagent
      .del(`${API_ROOT}${url}`)
      .use(tokenPlugin)
      .use(unauthorizedRedirect)
      .then(responseBody);
  },
  get: (url, params = {}) => {
    return superagent
      .get(`${API_ROOT}${url}`)
      .query(params)
      .use(tokenPlugin)
      .withCredentials()
      .use(unauthorizedRedirect)
      .then(responseBody);
  },
  put: (url, body) => {
    return superagent
      .put(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .use(unauthorizedRedirect)
      .then(responseBody);
  },
  post: (url, body) => {
    return superagent
      .post(`${API_ROOT}${url}`, body)
      .use(tokenPlugin)
      .withCredentials()
      .use(unauthorizedRedirect)
      .ok((res) => res.status < 500)
      .then(responseBody);
  },
};

const AppStorage = {
  setItem: (key, val) => localforage.setItem(key, val),
  getItem: (key) => localforage.getItem(key),
  removeItem: (key) => localforage.removeItem(key),
  clearStorage: () => localforage.clear(),
};
const Suggestions = {
  get: (url, params = {}) => Request.get(url, params),
};

const Items = {
  getAll: (params = { page: 1, searchKey: "", status: "" }) =>
    Request.get("/items", params),
  getSingle: (id) => Request.get(`/items/get_single`, { id }),
  credate: (data) => Request.post("/items/credate", data),
  delete: (id) => Request.post(`/items/delete`, { id }),
};

const Logs = {
  Items: (itemId) =>
    Request.get("/logs/get_logs", { table_id: itemId, table_name: "ITEMS" }),
};

const Session = {
  checkSession: () => Request.get("/accounts/checksession"),
  login: (data) => Request.post("/accounts/login", data),
  logout: () => Request.get("/accounts/logout"),
};

export default {
  setToken: (_token) => {
    token = _token;
  },
  AppStorage,
  Request,
  Items,
  Session,
  Suggestions,
  Logs,
};
