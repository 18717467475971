import { useState, useEffect } from "react";
import Autosuggest, { Theme } from "react-autosuggest";
import PropTypes from "prop-types";
import Api from "../Api";
import styled from "styled-components";

const SuggestWrapper = styled.div`
  input[type="text"],
  input[type="search"] {
    ${(props) => (props.error ? "border: 1px solid red;" : "")}
  }
`;

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

const InputSuggest = ({
  placeholder = "",
  type = "search",
  field = "name",
  name = "",
  label = "",
  value,
  onChange,
  id = null,
  onSuggestionSelected,
  apiUrl = "",
  displayField = "name",
  error = false,
}) => {
  const [valueInput, setValueInput] = useState(value);
  const [suggestions, setSuggestions] = useState([]);
  const [hasChanges, setHasChanges] = useState(true);

  const onChanges = (event, { newValue }) => {
    setValueInput(newValue);
    setHasChanges(false);
    if (onChange) {
      onChange(newValue);
    }
  };

  const fetchSuggestion = async (value = "") => {
    const _val = value + "";
    const escapedValue = escapeRegexCharacters(_val.trim());
    if (escapedValue === "") {
      setSuggestions([]);
    }

    const filter = {
      field: field,
      value: escapedValue,
    };

    const _apiUrl = apiUrl || "/suggestions/getfrom";
    const suggest = await Api.Suggestions.get(_apiUrl, filter);
    setSuggestions(suggest);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    console.log({ value });
  };

  const renderSuggestion = (suggestion) => {
    return <div>{suggestion[displayField]}</div>;
  };

  const getSuggestionValue = (suggestion) => {
    return `${suggestion[displayField]}`;
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
    // setHasChanges(false);
  };

  const suggestionSelected = (event, { suggestionValue }) => {
    setValueInput(suggestionValue);
    setHasChanges(true);

    if (onSuggestionSelected) {
      onSuggestionSelected(`${suggestionValue}`);
    }
  };

  useEffect(() => {
    setValueInput(value);
  }, [value]);

  useEffect(() => {
    if (valueInput && !hasChanges) {
      fetchSuggestion(valueInput);
    }
  }, [valueInput, hasChanges]);

  const inputProps = {
    placeholder: placeholder || "",
    type: type || "search",
    value: valueInput,
    onChange: onChanges,
    // onKeyUp: this.onKeyUp,
    // onKeyDown: this.onKeyDown,
    // onBlur: this.onBlur,
    // autoFocus: this.props.autoFocus,
    className: "form-control",
    autoComplete: "off",
  };

  return (
    <div className="text-left mb-3 form-group form-error">
      {label && <label htmlFor={name}>{label}</label>}
      <div className="px-0 col mt-2">
        <SuggestWrapper error={error}>
          <Autosuggest
            {...(id ? { id: id } : null)}
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            onSuggestionSelected={suggestionSelected}
            inputProps={inputProps}
          />
          {error && (
            <div className="invalid-feedback d-block">Field is required</div>
          )}
        </SuggestWrapper>
      </div>
    </div>
  );
};

InputSuggest.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  field: PropTypes.string,
  displayField: PropTypes.string,
  suggestSource: PropTypes.array,
  onChange: PropTypes.func,
  apiUrl: PropTypes.string,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  autoFocus: PropTypes.bool,
  onSuggestionSelected: PropTypes.func,
  error: PropTypes.bool,
};

export default InputSuggest;
