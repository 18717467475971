import numeral from "numeral";
import { format } from "date-fns";
import { isEmpty, union, eq, keys, filter } from "lodash";

export const getQueryParameters = (key) => {
  const query = window.location.search;

  const params = new URLSearchParams(query);

  return params.get(key) || "";
};

export const moneyFormat = (value) => {
  return numeral(value).format("0,0.00");
};

export const hmsMilliseconds = (h, m) => (h * 60 * 60 + m * 60) * 1000;

export const createActionTypes = (name) => ({
  REQUEST: `${name}/REQUEST`,
  SUCCESS: `${name}/SUCCESS`,
  FAILED: `${name}/FAILED`,
});

export const formatDate = (date, stringFormat = "MM/dd/yyyy") => {
  return format(new Date(date), stringFormat);
};

export const formatResponse = (data, options = { split: "-", mode: "response" }) => {
  const definedDateField = [];

  const objectData = Object.keys(data);
  if (objectData.length === 0) return data;

  if (options.mode === "payload") {
    delete data.updated_at;
    delete data.created_at;
  }

  const dateFields = Object.assign(
    [],
    definedDateField,
    objectData.filter((key) => key.includes("_date"))
  );
  const newData = { ...data };

  dateFields.forEach((key) => {
    if (data[key]) {
      const dateValue = `${data[key]}`.split(options.split);
      if (options.mode === "response") {
        newData[key] = `${dateValue[1]}/${dateValue[2]}/${dateValue[0]}`;
      } else {
        newData[key] = `${dateValue[2]}-${dateValue[0]}-${dateValue[1]}`;
      }
    }
  });

  return newData;
};

export const formatPayload = (data) => {
  return formatResponse(data, { split: "/", mode: "payload" });
};

export const validate = (data, model) => {
  const errors = {};

  for (const key in model) {
    const value = data[key];
    const { required } = model[key];

    if (required) {
      if (typeof value === "string" || !value) {
        if (!value || isEmpty(value)) {
          errors[key] = `${key} is required`;
        }
      }
      if (typeof value === "number") {
        if (!value) {
          errors[key] = `${key} is required`;
        }
      }
    }
  }

  return isEmpty(errors) ? { valid: true } : { valid: false, errors };
};

function isValidDate(testDate) {
  const date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
  if (!date_regex.test(testDate)) {
    return false;
  }
  return true;
}

export const detectChanges = (oldData, newData) => {
  delete oldData.updated_at;
  delete oldData.created_at;

  delete newData.updated_at;
  delete newData.created_at;

  const keyed = union(keys(oldData), keys(newData));
  const keyedChanges = filter(keyed, function (key) {
    const isDate = isValidDate(oldData[key]);

    const _oldValue = isDate ? `${oldData[key]}`.split("/") : oldData[key];
    let oldValue = _oldValue;

    const newValue = newData[key];
    if (isDate) {
      oldValue = `${oldValue[2]}-${oldValue[0]}-${oldValue[1]}`;
    }

    return !eq(`${oldValue}`.toString(), `${newValue}`.toString());
  });

  const changes = {};

  keyedChanges.forEach((key) => {
    changes[key] = `from ${oldData[key]} to ${newData[key]}`;
  });

  return changes;
};

export const tryParse = (str) => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};
