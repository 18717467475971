import { any, node } from "prop-types";

const PageContainer = ({ children, title }) => {
  return (
    <>
      <div className="border-bottom pt-4 pb-2 font-bold fs-6">{title}</div>
      <div className="mt-2 pl-5" style={{ height: "100%" }}>
        {children}
      </div>
    </>
  );
};

PageContainer.propTypes = {
  children: node,
  title: any,
};

export default PageContainer;
