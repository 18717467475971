import { combineReducers } from "redux";
import { reducers as Account } from "modules/Login";

const createRootReducer = (history) =>
  combineReducers({
    router: history,
    account: Account,
  });

export default createRootReducer;
