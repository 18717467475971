import React from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

class Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: props.visible,
    };
    this.onButtonClick = this.onButtonClick.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }

  onButtonClick() {
    // Since the modal is inside the button click events will propagate up.
    if (!this.state.isOpened) {
      this.setState({
        isOpened: true,
      });
    }
  }

  onClose(event) {
    if (event) {
      event.stopPropagation();
    }
    this.setState({
      isOpened: false,
    });

    if (typeof this.props.onClose === "function") {
      this.props.onClose();
    }
  }

  onConfirm(event) {
    event.stopPropagation();
    this.setState({
      isOpened: false,
    });
    this.props.onConfirm();
  }

  render() {
    const cancelButton = this.props.showCancelButton ? (
      <Button color="secondary" onClick={this.onClose}>
        {this.props.cancelText}
      </Button>
    ) : null;
    const modal = (
      <Modal
        isOpen={this.state.isOpened}
        onClosed={this.onClose}
        className={this.props.className}
        backdrop={this.props.backdrop}
      >
        <ModalHeader>{this.props.title}</ModalHeader>
        <ModalBody>{this.props.body}</ModalBody>
        <ModalFooter>
          <Button color={this.props.confirmBSStyle} onClick={this.onConfirm}>
            {this.props.confirmText}
          </Button>
          {cancelButton}
        </ModalFooter>
      </Modal>
    );
    let content;
    if (this.props.children) {
      const btn = React.Children.only(this.props.children);
      content = React.cloneElement(
        btn,
        {
          onClick: this.onButtonClick,
          style: this.props.style,
        },
        btn.props.children,
        modal
      );
    } else {
      content = (
        <Button onClick={this.onButtonClick} style={this.props.style}>
          {this.props.buttonText}
          {modal}
        </Button>
      );
    }
    return content;
  }
}

Confirm.propTypes = {
  body: PropTypes.node.isRequired,
  buttonText: PropTypes.node,
  cancelText: PropTypes.node,
  className: PropTypes.string,
  confirmBSStyle: PropTypes.string,
  confirmText: PropTypes.node,
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["static"])]),
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  showCancelButton: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  visible: PropTypes.bool,
};

Confirm.defaultProps = {
  cancelText: "Cancel",
  confirmText: "Confirm",
  confirmBSStyle: "danger",
  showCancelButton: true,
};

export default Confirm;
