import { useEffect, useState } from "react";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { any, array, bool, func, string } from "prop-types";

const RSelect = ({
  options = [],
  label = "",
  name = "",
  value = "",
  onChange,
  disabled = false,
  placeholder = "Select...",
  isMulti = false,
}) => {
  const [selected, setSelected] = useState(value);

  const handleChange = (selectedValues) => {
    setSelected(selectedValues);

    const e = {
      target: {
        name: name,
        value: isMulti ? selectedValues : selectedValues.value,
      },
    };
    onChange && onChange(e);
  };

  const setValues = () => {
    // eslint-disable-next-line eqeqeq
    const selectedValues = options.find((option) => option.value == value);

    setSelected(selectedValues);
  };

  useEffect(() => {
    setValues();
  }, [value, options]);

  return (
    <FormGroup className={disabled ? "" : "mb-3"}>
      {label && <Label className="mb-2">{label}</Label>}
      {disabled && selected ? (
        <div style={{ fontWeight: "bold", fontSize: "21px" }}>{selected.label}</div>
      ) : (
        <Select
          options={options}
          onChange={handleChange}
          value={selected}
          defaultValue=""
          isDisabled={disabled}
          placeholder={placeholder}
          isMulti={isMulti}
        />
      )}
    </FormGroup>
  );
};

RSelect.propTypes = {
  options: array,
  label: string,
  name: string,
  placeholder: string,
  value: any,
  onChange: func,
  disabled: bool,
  isMulti: bool,
};

export default RSelect;
