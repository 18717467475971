import Api from "Api";
import { useEffect, useState } from "react";
import { Button, Alert } from "reactstrap";
import styled from "styled-components";
import InputComponent from "utils/Input";

const LoginWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  .form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
  }

  .form-signin .checkbox {
    font-weight: 400;
  }

  .form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
`;
const Login = () => {
  const [formdata, setFormdata] = useState({});
  const [error, setError] = useState("");
  const date = new Date();

  const handleChange = (e) => {
    const { name, value } = e.target;

    const v = e.target.type === "checkbox" ? e.target.checked : value;
    setFormdata({ ...formdata, [name]: v });
  };

  const doSignin = async () => {
    setError("");

    if (formdata.remember) {
      await Api.AppStorage.setItem("loginData", btoa(JSON.stringify(formdata)));
    } else {
      await Api.AppStorage.removeItem("loginData");
    }

    const response = await Api.Session.login(formdata);

    if (response.status) {
      window.location.href = "/";
    } else {
      setError(response.message);
    }
  };

  const getSaveData = async () => {
    const response = await Api.AppStorage.getItem("loginData");
    if (response) {
      const data = JSON.parse(atob(response));
      setFormdata(data);
    }
  };

  useEffect(() => {
    getSaveData();
  }, []);

  return (
    <LoginWrapper>
      <form className="form-signin">
        {/* <img
          className="mb-4"
          src={process.env.PUBLIC_URL + `/littletoes.jpeg`}
          alt=""
          width="100"
          height="100"
        /> */}
        <div style={{ fontSize: "30px" }} className="mb-4">
          Inventory Management
        </div>
        <h1 className="h3 mb-3 fw-normal">Please sign in</h1>
        {error && <Alert color="danger">{error}</Alert>}
        <div className="form-floating">
          <InputComponent
            label="Username"
            name="username"
            onChange={handleChange}
            value={formdata?.username || ""}
          />
        </div>
        <div className="form-floating">
          <InputComponent
            label="Password"
            name="password"
            type="password"
            onChange={handleChange}
            value={formdata.password || ""}
          />
        </div>

        <div className="checkbox mb-3 mt-3">
          <label>
            <input
              type="checkbox"
              name="remember"
              value="remember"
              checked={formdata.remember || false}
              onChange={handleChange}
            />{" "}
            Remember me
          </label>
        </div>
        <Button
          className="w-100 btn btn-lg"
          color="primary"
          type="button"
          onClick={doSignin}
        >
          Sign in
        </Button>
        <p className="mt-5 mb-3 text-muted">&copy; {date.getFullYear()}</p>
      </form>
    </LoginWrapper>
  );
};

export default Login;
