import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { createStore, applyMiddleware, compose } from "redux";
// import { routerMiddleware } from "connected-react-router";
import { persistStore } from "redux-persist";
import createRootReducer from "./reducers";

export const history = createBrowserHistory();

let store;

const configureStore = (preloadedState) => {
  const debug =
    window.localStorage.getItem("debug") || process.env.NODE_ENV !== "production";

  const composeEnhancer =
    (debug && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  store = createStore(
    createRootReducer(history),
    preloadedState,
    composeEnhancer(applyMiddleware(thunk))
  );

  // store.subscribe((state) => console.log("LISTENING: ", store.getState()));
  const persistor = persistStore(store);

  return { store, persistor };
};

export const getStore = () => store;

export default configureStore;
