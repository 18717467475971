import { INITIAL_STATE, SET_USER } from "./constant";

export const reducers = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
