import "./App.css";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";

import { Switch, Route } from "react-router";
import { BrowserRouter } from "react-router-dom";

import Login from "modules/Login";
import Landing from "modules/Landing";
import React from "react";
import configureStore, { history } from "./redux/store";

const queryClient = new QueryClient();
const { store } = configureStore({});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/" component={Landing} />
          </Switch>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
