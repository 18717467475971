import Api from "Api";

export const fetchItems = async (
  params = { page: 1, searchKey: "", status: "" }
) => {
  const result = await Api.Items.getAll(params);
  return result;
};

export const fetchSession = async () => {
  const result = await Api.Session.checkSession();
  return result;
};
