import { useEffect } from "react";
import { useQuery } from "react-query";
import { Route } from "react-router";

import { Row } from "reactstrap";
import TopBar from "modules/Headers/TopBar";
import { LeftNav } from "modules/Navs";
import { fetchSession } from "ApiCall";
import { Redirect } from "react-router-dom";

import { useDispatch } from "react-redux";
import { setUser } from "modules/Login";

import { ItemListing, Details } from "modules/Items";
import { Dashboard } from "modules/Dashboard";

const Landing = () => {
  const dispatch = useDispatch();
  const { data = {}, isLoading } = useQuery("fetchSession", fetchSession);

  useEffect(() => {
    if (isLoading === false) {
      if (!data?.status === true) {
        window.location.href = "/login";
      } else {
        dispatch(setUser(data.user));
      }
    }
  }, [isLoading]);

  return (
    <div className="container-fluid">
      <Row>
        <TopBar />
        <LeftNav />
        <main
          className="col-md-9 ms-sm-auto col-lg-10 px-md-4"
          style={{ height: "calc(100vh - 50px)" }}
        >
          <Route path="/" exact component={() => <Redirect to="/items" />} />
          <Route path="/dashboard" exact component={Dashboard} />
          <Route path="/items" exact component={ItemListing} />
          <Route path="/items/details/:itemId" component={Details} />
        </main>
      </Row>
    </div>
  );
};

export default Landing;
