import React from "react";
import { createRoot } from "react-dom/client";
// import ReactDOM from "react-dom";

import localforage from "localforage";
import "bootstrap/dist/css/bootstrap.min.css";
import "toastr/build/toastr.min.css";
import "./index.css";

import App from "./App";

localforage.config({ name: "go-inventory" });

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);

// ReactDOM.render(<App />, container);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
