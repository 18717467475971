import { BarController } from "chart.js";
import PageContainer from "modules/Container";
import BarChart from "./BarChart";

import {
  Card,
  CardImg,
  CardText,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Row,
  Col,
} from "reactstrap";

const Home = () => {
  return (
    <PageContainer title="Dashboard">
      <Row>
        <Col md={3}>
          <Card>
            <CardBody>
              <CardTitle tag="h5" className="border-bottom">
                Card title
              </CardTitle>
              <CardText>30 Items</CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </PageContainer>
  );
};

export default Home;
