import React, { PureComponent } from "react";
import ultimatePagination, { ITEM_TYPES } from "ultimate-pagination";
import PropTypes from "prop-types";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

class PaginationUI extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      paginationModel: [],
    };
  }
  pagingModel() {
    const paginationModel = ultimatePagination.getPaginationModel({
      // Required
      currentPage: this.props.activePage || 1,
      totalPages: this.props.totalPage || 1,

      // Optional
      boundaryPagesRange: 3,
      siblingPagesRange: 2,
      hideEllipsis: false,
      hidePreviousAndNextPageLinks: this.props.totalPage < 2,
      hideFirstAndLastPageLinks: this.props.totalPage < 5,
    });
    return paginationModel;
  }
  onChangePage = (page) => this.props.onSelect(page);
  render() {
    const paginationModel = this.pagingModel();
    if (this.props.totalPage && this.props.totalPage === 1) return null;

    return (
      <div className="pull-right text-center">
        <Pagination>
          {paginationModel.map((model) => {
            let content;
            const Click = (e) => {
              e.preventDefault();
              this.onChangePage(model.value);
            };

            if (model.type === ITEM_TYPES.FIRST_PAGE_LINK) {
              content = (
                <PaginationItem
                  key={`model-${model.key}`}
                  disabled={this.props.activePage === 1}
                >
                  <PaginationLink onClick={Click}>First</PaginationLink>
                </PaginationItem>
              );
            }

            if (model.type === ITEM_TYPES.PREVIOUS_PAGE_LINK) {
              content = (
                <PaginationItem
                  key={`model-${model.key}`}
                  disabled={this.props.activePage === 1}
                >
                  <PaginationLink previous onClick={Click} />
                </PaginationItem>
              );
            }

            if (model.type === ITEM_TYPES.PAGE) {
              content = (
                <PaginationItem active={model.isActive} key={`model-${model.key}`}>
                  <PaginationLink onClick={Click}>{model.value}</PaginationLink>
                </PaginationItem>
              );
            }

            if (model.type === ITEM_TYPES.ELLIPSIS) {
              content = (
                <PaginationItem disabled key={`model-${model.key}`}>
                  <PaginationLink>...</PaginationLink>
                </PaginationItem>
              );
            }

            if (model.type === ITEM_TYPES.NEXT_PAGE_LINK) {
              content = (
                <PaginationItem
                  key={`model-${model.key}`}
                  disabled={this.props.totalPage === this.props.activePage}
                >
                  <PaginationLink next onClick={Click} />
                </PaginationItem>
              );
            }

            if (model.type === ITEM_TYPES.LAST_PAGE_LINK) {
              content = (
                <PaginationItem
                  key={`model-${model.key}`}
                  disabled={this.props.totalPage === this.props.activePage}
                >
                  <PaginationLink onClick={Click}>Last</PaginationLink>
                </PaginationItem>
              );
            }

            return content;
          })}
        </Pagination>
      </div>
    );
  }
}

PaginationUI.propTypes = {
  activePage: PropTypes.number,
  totalPage: PropTypes.number,
  onSelect: PropTypes.func,
};
PaginationUI.defaultProps = {
  totalPage: 1,
  activePage: 1,
};

export default PaginationUI;
