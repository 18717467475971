import { useEffect, useState } from "react";
import PageContainer from "modules/Container";
import InputComponent from "utils/Input";
import classnames from "classnames";

import {
  Row,
  Col,
  ButtonGroup,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  ListGroup,
  ListGroupItem,
  List,
} from "reactstrap";
import styled from "styled-components";
import {
  detectChanges,
  formatDate,
  formatPayload,
  formatResponse,
  tryParse,
} from "helper";
import Api from "Api";
import { useParams, useHistory } from "react-router-dom";
import InputSuggest from "utils/InputSuggest";
import Notify from "Notify";
import { validate } from "helper";
import { ITEM_STATUS } from "constant";
import { FaArrowLeft, FaRegSave } from "react-icons/fa";
import { startCase } from "lodash";
const StyledContainer = styled(PageContainer)`
  > .form-group {
    margin-bottom: 5px;
  }
`;

const ItemModel = {
  part: {
    required: true,
  },
  part_num: {
    required: true,
  },
  brand: {
    required: true,
  },
  model_type: {
    required: true,
  },
};

const Details = () => {
  const history = useHistory();
  const params = useParams();
  const isEditing = params?.itemId !== "new";
  const [formdata, setFormdata] = useState({
    status: "New",
  });

  const [originalData, setOriginalData] = useState({});

  const [logs, setLogs] = useState([]);

  const [errors, setErrors] = useState({});

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    if (tab === "2") {
      getLogs();
    }
  };

  const handleChange = (e) => {
    setFormdata({ ...formdata, [e.target.name]: e.target.value });
  };

  const handleDateChange = (field, value) => {
    setFormdata({ ...formdata, [field]: formatDate(value) });
  };

  const submit = async () => {
    setErrors({});
    const validation = validate(formdata, ItemModel);

    if (validation.valid) {
      const payload = formatPayload(formdata);

      if (payload?.id) {
        const changes = detectChanges(originalData, payload);

        if (changes) {
          payload.field_changes = JSON.stringify(changes);
        }
      }
      await Api.Items.credate(payload);

      Notify.success(
        `Item ${isEditing ? "updated" : "created"} successfully`,
        "Success"
      );

      if (!isEditing) {
        setTimeout(() => {
          history.push("/items");
        }, 2000);
      }
    } else {
      setErrors(validation.errors);
    }
  };

  const getSingleItem = async () => {
    const response = await Api.Items.getSingle(params.itemId);
    const data = formatResponse(response);
    setFormdata(data);
    setOriginalData(data);
  };

  const getLogs = async () => {
    const response = await Api.Logs.Items(params.itemId);
    setLogs(response);
  };

  useEffect(() => {
    if (params?.itemId !== "new") {
      getSingleItem();
      getLogs();
    }
  }, [params?.itemId]);

  return (
    <PageContainer
      title={isEditing ? `Item Details - ${formdata.part}` : "Item Details"}
    >
      <StyledContainer>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === "1" })}
              style={{ cursor: "pointer" }}
              onClick={() => {
                toggle("1");
              }}
            >
              ITEM DETAILS
            </NavLink>
          </NavItem>
          {isEditing && (
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
              >
                LOGS
              </NavLink>
            </NavItem>
          )}
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" style={{ paddingTop: "20px" }}>
            <Row>
              <Col>
                <InputComponent
                  label="Part"
                  name="part"
                  onChange={handleChange}
                  value={formdata?.part || ""}
                  error={errors?.part}
                />
              </Col>
              <Col>
                <InputComponent
                  label="Part #"
                  name="part_num"
                  onChange={handleChange}
                  value={formdata?.part_num || ""}
                  error={errors?.part_num}
                />
              </Col>
            </Row>
            <Row>
              <Col md={3}>
                <InputSuggest
                  label="Unit Brand"
                  apiUrl="/items/get_previousvalue"
                  field="brand"
                  displayField="brand"
                  value={formdata?.brand || ""}
                  error={errors?.brand}
                  onChange={(v) =>
                    handleChange({ target: { name: "brand", value: v } })
                  }
                />
              </Col>
              <Col md={3}>
                <InputComponent
                  label="Model Type"
                  name="model_type"
                  error={errors?.model_type}
                  value={formdata?.model_type || ""}
                  onChange={handleChange}
                />
              </Col>
              <Col md={2}>
                <InputComponent
                  label="Status"
                  name="status"
                  type="select"
                  onChange={handleChange}
                  value={formdata?.status}
                >
                  <option>Select Status</option>
                  {Object.keys(ITEM_STATUS).map((key) => {
                    return (
                      <option key={key} value={key}>
                        {key}
                      </option>
                    );
                  })}
                </InputComponent>
              </Col>
              <Col>
                <InputComponent
                  label="Item Location"
                  name="item_location"
                  value={formdata?.item_location || ""}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputComponent
                  label="Description Date"
                  type="date"
                  name="description_date"
                  value={formdata?.description_date || ""}
                  onChange={(v) => handleDateChange("description_date", v)}
                />
              </Col>
              <Col>
                <InputComponent
                  label="Description"
                  name="description"
                  value={formdata?.description || ""}
                  onChange={handleChange}
                />
              </Col>
              <Col>
                <InputComponent
                  label="Description By"
                  name="description_by"
                  value={formdata?.description_by || ""}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <InputComponent
                  label="Purchase Date"
                  type="date"
                  name="purchase_date"
                  value={formdata?.purchase_date || ""}
                  onChange={(v) => handleDateChange("purchase_date", v)}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <InputComponent
                  label="Sign Out To"
                  name="signout_to"
                  value={formdata?.signout_to || ""}
                  onChange={handleChange}
                />
              </Col>
              <Col md={4}>
                <InputComponent
                  label="Signout Date"
                  type="date"
                  name="signout_date"
                  value={formdata?.signout_date || ""}
                  onChange={(v) => handleDateChange("signout_date", v)}
                />
              </Col>
              <Col md={4}>
                <InputComponent
                  label="Sign Out By"
                  name="signout_by"
                  value={formdata?.signout_by || ""}
                  onChange={handleChange}
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <ButtonGroup>
                <Button
                  color="primary"
                  onClick={() => {
                    history.push("/items");
                  }}
                >
                  <FaArrowLeft /> Back
                </Button>
                <Button color="success" onClick={submit}>
                  <FaRegSave /> {isEditing ? "Update" : "Create"}
                </Button>
              </ButtonGroup>
            </div>
          </TabPane>
          <TabPane tabId="2" style={{ paddingTop: "20px" }}>
            <ListGroup>
              {logs.length > 0 ? (
                logs.map((log, index) => {
                  const logsData =
                    log.data !== "DELETED ITEM"
                      ? tryParse(log.data)
                      : { Archive: "DELETED ITEM" };

                  const keys = Object.keys(logsData).map((key, i) => {
                    return (
                      <li key={i}>
                        Change <strong>{startCase(key)}</strong> {logsData[key]}
                      </li>
                    );
                  });

                  return (
                    <ListGroupItem key={index}>
                      <List type="unstyled">
                        <li style={{ color: "navy" }}>
                          User: {log.username} -{" "}
                          {formatDate(log.created_at, "MM/dd/yyyy hh:mm aa")}
                        </li>
                        <li>
                          <ul>{keys}</ul>
                        </li>
                      </List>
                    </ListGroupItem>
                  );
                })
              ) : (
                <ListGroupItem>No logs found...</ListGroupItem>
              )}
            </ListGroup>
          </TabPane>
        </TabContent>
      </StyledContainer>
    </PageContainer>
  );
};

export default Details;
